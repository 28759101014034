
 import { PageBase } from '@/core/models/shared';
import { EmpreendimentoService, PessoaService, TipoAditamentoContrato } from '@/core/services/cadastros';
import { EmpresaService } from '@/core/services/compras/EmpresaService';
import { FormaPagamentoService, RelatorioService, SituacaoReceitaParcelaService, TipoReceitaService, TipoServicoReceitaService } from '@/core/services/financeiro';
import { Component } from 'vue-property-decorator';

@Component
export default class RelatorioReceita extends PageBase { 

    service = new RelatorioService(); 
    item = new Relatorio();
    overlay: boolean = false;
    
    filter:any = {};

    empreendimentos = [];
    pessoas = [];
    tiposReceita = [];
    tiposServicos = [];
    empresas = [];
    tiposAditamento = [];
    situacoesParcela = [];
    formasPagamento = [];

    mounted() { 
        this.filter = this.$route.query;
        this.overlay = true;
        
        this.service.ListarReceitaDetalhada(this.filter.empreendimentoId,  this.filter.numeroContrato,  this.filter.pagamentoAutomatico,  this.filter.formaPagamento,  this.filter.usuarioBaixa,  this.filter.clienteId,  this.filter.situacaoParcelaId,  this.filter.contratoId,  this.filter.tipoReceitaId,  this.filter.dataVencimentoInicial,  this.filter.dataVencimentoFinal,  this.filter.dataBaixaInicial,  this.filter.dataBaixaFinal,  this.filter.empresaId,  this.filter.tipoAditamentoId,  this.filter.tipoServicoId)
            .then(
                res => {   
                    this.item = res.data;
                 }, 
                err => {  
                    if (!err.response) {
                        this.$swal("Aviso", "Não foi possível acessar a API", "error");
                    } else {
                        this.$swal("Aviso", err.response.data, "error");
                    }
                }).finally(() => {
                this.overlay = false;
            });
    } 
}

class Relatorio {
    quantidadeParcelasTotal:number = 0;
    jurosTotal:number = 0;
    descontoTotal:number = 0;
    valorTotal:number = 0;
    valorPagoTotal:number = 0;
    parcelas: RelatorioParcela[] = [];
}

class RelatorioParcela {
    encargosTotal:number = 0;
    numeroSequenciaContrato:string = "";
    parcelaNumero:number = 0;
    totalParcelas:number = 0;
    clienteNome:string ="";
    empreendimentoNome:string =''; 
    unidadeQuadra:string =''; 
    unidadeLote:string ='';   
    tipoReceita:string ='';    
    vencimentoParcela:string ='';   
    dataPagamentoParcela:string ='';   
    valorParcela:number = 0;  
    situacaoParcela:string ='';   
    formaPagamentoParcela:string ='';   
    baixaAutomatica:boolean = false;  
    jurosParcelaBaixa:number =0  
    multaParcelaBaixa:number =0 
    descontosParcelaBaixa:number =0   
    valorPagoParcela:number =0   
    valorTotalPagoParcela:number =0   
    usuarioBaixa:string ='';  
    parcelasAgrupadas :string ='';     
    empresaNome: string ='';
    tipoAditamentoNome:string ='';
    clienteCPF:string = "";
    numeroDocumento:string = "";
    tipoGatewayNome:string = "";
}

